@import url('https://fonts.cdnfonts.com/css/gotham');
@import url('https://fonts.cdnfonts.com/css/poppins');

body {
  font-family: 'Gotham', 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --colorPrimary-Blue: #2A6EDB;
  --colorPrimary-Green: #42C67E;
  --colorPrimary-Black: #232323;
  --colorPrimary-Gray: #88919E;
  --colorSecondary-Green: #79DD8B;
  --colorSecondary-Blue: #1AA4CC;
  --colorSecondary-Black: #959595;
  --colorSecondary-Gray: #E3E3E3;
  --colorQuaternary-Green: #E8F6F3;
  --colorHover-Green: #F0F9F7;
  --colorHoverSecondary-Green: #F6FBFA;
  --colorHoverText-Green: #80CFBC;
  --colorPrimary-Error: #FF655A;
  --hovermenta: #C0E7DE;
  --contrastementa: #61B09D;
  --secundariomenta: #99D9C9;
  --secundarioceleste: #69C4DE;
  --neutralgrisclaro: #E2E5E8;
  --blur-shadow-to-button: rgba(42, 110, 219, 0.25) 0px 54px 55px, rgba(42, 110, 219, 0.12) 0px -12px 30px, rgba(42, 110, 219, 0.12) 0px 4px 6px, rgba(42, 110, 219, 0.17) 0px 12px 13px, rgba(42, 110, 219, 0.09) 0px -3px 5px;

}

::-webkit-scrollbar {
  width: 20px;
  height: 20px;
  border: 2px solid #000000da;
  /* display: none; */
}

::-webkit-scrollbar-track {
  display: flex;
  background: #fff;
  /* border-top: 4px solid #FBD6C5;
  border-left: 4px solid #FBD6C5; */

}

::-webkit-scrollbar-thumb {
  background-color: var(--neutralhover, #A7B1BF);
  border-radius: 20px;
  border: 6px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  border: 5px solid #fff;
}

::-webkit-scrollbar-corner {
  background: none;
}